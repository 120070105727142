import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import { Modal, Button  } from 'semantic-ui-react'
//<Button href="https://www.yarinanichi.jewelry/portfolio" target="_blank">High-End Jewelry</Button>

class Menu extends Component {

    render(){
        return(

        <Button.Group widths='3' color='pink'>

        <Modal trigger={<Button>About Us</Button>}closeIcon>
        <Modal.Content scrolling>

        <Modal.Description>

            <p align="center"><strong>About Us</strong></p>

            <p align="justify">Yari Nanichi design and manufacture handmade jewelry
            and luxury art objects with the following principles:</p>

            <p align="justify">•	Handmade with flawless craftsmanship quality.
            </p>

            <p align="justify">•	The use of the finest materials in an extremely
            desirable beautiful object difficult to resist produced in scarcity.
            </p>

            <p align="justify">•	Yari Nanichi is not a merchant selling mass
            produced commercial jewelry.  I am a graduated artist in Fine Arts
            and goldsmithing, working in my own art studio, creating handmade
            jewelry for customers who appreciate exclusive designs.
            </p>

            <p align="justify">•	Our High-End Jewelry explore creativity for aesthetic
            revolutions. Making jewelry sculptures as art to wear to elevate our
            human condition through aesthetic conceptualization.
            </p>
                      
          </Modal.Description>
        </Modal.Content>
       </Modal>
           <Button as={Link} to={"/Courses"}>Jewelry Courses</Button>
           <Button href="https://www.yarinanichi.jewelry/contact-us.html">Contact</Button>
            </Button.Group>


        )
    }
}



export default Menu;
