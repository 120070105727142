import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';


class Pagination4 extends Component {

    render(){
        return(

<div align="center">
<Button.Group>
        <Link to="/"><Button style={{background: '#404040', color:'white'}}><p>1</p></Button></Link>
        <Link to="./App2"><Button style={{background: '#404040', color:'white'}}><p>2</p></Button></Link>
        <Link to="./App3"><Button style={{background: '#404040', color:'white'}}><p>3</p></Button></Link>
        <Link to="./App4"><Button style={{background: '#404040', color:'red'}}><p>4</p></Button></Link>
        <Link to="./App5"><Button style={{background: '#404040', color:'white'}}><p>5</p></Button></Link>
        <Link to="./App6"><Button style={{background: '#404040', color:'white'}}><p>6</p></Button></Link>
</Button.Group>
</div>

        )
    }
}



export default Pagination4;
