//import React, { Component } from 'react';
import React from 'react';
//import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSC36LTQU01 from './images/ESSC36LTQU01_1920x1080.jpg';
import ImageESSK34AMET01 from './images/ESSK34AMET01_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottomhome from './navigation/menubottomhome';
import Pagination from './navigation/pagination';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
import VideoESSC36LTQU01 from './videos/ESSC36LTQU01.mp4';
import VideoESSK34AMET01 from './videos/ESSK34AMET01.mp4';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ESSC36LTQU01PayPal from './paypal/ESSC36LTQU01PayPal.js';
//import ESSK34AMET01PayPal from './paypal/ESSK34AMET01PayPal.js';
import 'semantic-ui-css/semantic.min.css';

//import ReactGA from 'react-ga';


function App() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSC36LTQU01}
             src={VideoESSC36LTQU01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Turquoise crystals from Swarovski®.  Hammered texture and polished like a mirror.
    Extremely comfortable to wear with a sterling silver post and clutch disc system.
    Made in USA with imported Swarovski® crystals from Austria. Play the video
    in full screen to appreciate the beauty and craftsmanship.
    </p>
    <p>
    <strong>Price $168.75 (25% off from $225.00)</strong>
    </p>
    <p>
    <strong>Free USPS Priority Mail Shipping</strong>
    </p>
    <ESSC36LTQU01PayPal/>
    </div>
    <br/><br/>

          <div className="video">

              <Player
                   fluid
                   preload='none'
                   LoadingSpinner
                   poster={ImageESSK34AMET01}
                   src={VideoESSK34AMET01}
                 />

          </div>

          <div align="center" className="token_interface">
          <br/>
          <p align="justify">Handmade sterling silver earrings, embellished with
          Amethyst crystals from Swarovski®.  Hammered texture and polished like a mirror.
          Extremely comfortable to wear with a sterling silver post and clutch disc system.
          Made in USA with imported Swarovski® crystals from Austria. Play the video
          in full screen to appreciate the beauty and craftsmanship.
          </p>
          <h2>Sold</h2>
          <br/>
          </div>

          <div className="pagination">
          <Pagination/>
          </div>
          <div className="buttons-bar">
          <Menubottomhome/>
          </div>
          <div className="bottom-strip">
          <br/><br/>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <br/>
          </div>


                </main>
              );
            }

            export default App;
