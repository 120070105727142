import React from 'react';
//import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import ScrollToTop from './ScrollToTop';
import App from './App';
import App2 from './App2';
import App3 from './App3';
import App4 from './App4';
import App5 from './App5';
import App6 from './App6';
import ThankYou from './ThankYou';
import CourseThankYou from './CourseThankYou';
import Twty from './Twty';
import SandboxPayPal from './SandboxPayPal';

import ESSC16LSAP02 from './catalog/earrings/ESSC16LSAP02';
import ESSC16XIAB01 from './catalog/earrings/ESSC16XIAB01';
import ESSC32LSAP01 from './catalog/earrings/ESSC32LSAP01';
import ESSC36LTQU01 from './catalog/earrings/ESSC36LTQU01';
import ESSH16DRBE01 from './catalog/earrings/ESSH16DRBE01';
import ESSK34AMET01 from './catalog/earrings/ESSK34AMET01';
import ESSK34EMER01 from './catalog/earrings/ESSK34EMER01';
//import ESSK34ROPE01 from './catalog/earrings/ESSK34ROPE01';
import ESSK34TANZ01 from './catalog/earrings/ESSK34TANZ01';
import ESSK38LSIA01 from './catalog/earrings/ESSK38LSIA01';
import ESSP32ROSE01 from './catalog/earrings/ESSP32ROSE01';
//import ESSS28FUCH01 from './catalog/earrings/ESSS28FUCH01';
import ESSS28LSIA01 from './catalog/earrings/ESSS28LSIA01';
import ESSS30BZIR45 from './catalog/earrings/ESSS30BZIR45';
import ESSS30ROSE45 from './catalog/earrings/ESSS30ROSE45';
import HighEnd from './HighEnd';
import Courses from './Courses';
import Coursesv2 from './Coursesv2';
import ContactForm from './ContactForm';
import PrivacyPolicy from './PrivacyPolicy';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
//import ReactGA from 'react-ga';


ReactDOM.render(
<BrowserRouter>
<ScrollToTop />
  <div>
  <Route exact path="/" component={App} />
  <Route exact path="/App2" component={App2} />
  <Route exact path="/App3" component={App3} />
  <Route exact path="/App4" component={App4} />
  <Route exact path="/App5" component={App5} />
  <Route exact path="/App6" component={App6} />
  <Route exact path="/HighEnd" component={HighEnd} />
  <Route exact path="/Courses" component={Courses} />
  <Route exact path="/Coursesv2" component={Coursesv2} />
  <Route exact path="/ContactForm" component={ContactForm} />
  <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
  <Route exact path="/ThankYou" component={ThankYou} />
  <Route exact path="/CourseThankYou" component={CourseThankYou} />
  <Route exact path="/Twty" component={Twty} />
  <Route exact path="/SandboxPayPal" component={SandboxPayPal} />
  <Route exact path="/catalog/earrings/ESSC16LSAP02" component={ESSC16LSAP02} />
  <Route exact path="/catalog/earrings/ESSC16XIAB01" component={ESSC16XIAB01} />
  <Route exact path="/catalog/earrings/ESSC32LSAP01" component={ESSC32LSAP01} />
  <Route exact path="/catalog/earrings/ESSC36LTQU01" component={ESSC36LTQU01} />
  <Route exact path="/catalog/earrings/ESSH16DRBE01" component={ESSH16DRBE01} />
  <Route exact path="/catalog/earrings/ESSK34AMET01" component={ESSK34AMET01} />
  <Route exact path="/catalog/earrings/ESSK34EMER01" component={ESSK34EMER01} />
  <Route exact path="/catalog/earrings/ESSK34TANZ01" component={ESSK34TANZ01} />
  <Route exact path="/catalog/earrings/ESSK38LSIA01" component={ESSK38LSIA01} />
  <Route exact path="/catalog/earrings/ESSP32ROSE01" component={ESSP32ROSE01} />
  <Route exact path="/catalog/earrings/ESSS28LSIA01" component={ESSS28LSIA01} />
  <Route exact path="/catalog/earrings/ESSS30BZIR45" component={ESSS30BZIR45} />
  <Route exact path="/catalog/earrings/ESSS30ROSE45" component={ESSS30ROSE45} />
    </div>
  </BrowserRouter>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
