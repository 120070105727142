import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';


class Menubottomcourses extends Component {

    render(){
        return(

        <Button.Group widths='3' color='pink'>
          <Button as={Link} to={"/"}>Home</Button>
        </Button.Group>


        )
    }
}



export default Menubottomcourses;
