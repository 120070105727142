//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import ImageMJMB from './images/MJMB.png';
import ImageMJN from './images/MJN.png';
import ImageMJME from './images/MJME.png';
import ImageHR from './images/HR.png';
import ImageDTE from './images/DTE.png';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
import MJMB from './videos/MJMB.mp4';
import MJN from './videos/MJN.mp4';
import MJME from './videos/MJME.mp4';
import AR from './videos/AR.mp4';
import DTE from './videos/DTE.mp4';
import AccordionHighEnd from './accordionHighEnd';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



function HighEnd() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">
        <Player
             fluid
             preload="none"
             LoadingSpinner
             poster={ImageMJMB}
             src={MJMB}
           />
    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify"><strong>Meta-Joy Monolithic Bangle:</strong> Platinum or
    18k yellow gold, fused glass enamel and 2,349 diamonds with a total
    approximation weight of 50 carats.
    <br/>
    </p>
    <br/>
      <AccordionHighEnd/>
    <br/>
    <br/>
   </div>

   <br/><br/>

   <div className="video">
       <Player
            fluid
            preload="none"
            LoadingSpinner
            poster={ImageMJN}
            src={MJN}
          />
   </div>

   <div align="center" className="token_interface">
   <br/>
   <p align="justify"><strong>Meta-Joy Necklace and Earrings:</strong> Platinum or 18k yellow gold,
       fused glass enamel and 298 diamonds with a total approximation weight of 32 carats.
       Colored Briolettes can be selected from the following gemstones:
       Alexandrite, Amethyst, Aquamarine, Benitoite, Blue Sapphire, Chrome Tourmaline,
       Emerald, Indicolite Tourmaline, Iolite, Jadeite, Mahenge Spinel,
       Mandarin Spessartite Garnet, Paraiba Tourmaline, Peridot, Pink Sapphire,
       Pink Spinel, Pink Tourmaline, Purple Garnet, Rubellite Tourmaline, Ruby,
       Swiss Blue Topaz, Tanzanite, Tsavorite Garnet.
    </p>
   <br/>
     <AccordionHighEnd/>
   <br/>
   <br/>
   </div>

   <br/><br/>

   <div className="video">
       <Player
            fluid
            preload="none"
            LoadingSpinner
            poster={ImageMJME}
            src={MJME}
          />
   </div>

   <div align="center" className="token_interface">
   <br/>
   <p align="justify"><strong>Meta-Joy Molecule Earrings:</strong> Platinum or 18k yellow gold,
       fused glass enamel and 1,087 diamonds with a total approximation weight of 6.5 carats.
       Colored Briolettes can be selected from the following gemstones:
       Alexandrite, Amethyst, Aquamarine, Benitoite, Blue Sapphire, Chrome Tourmaline,
       Emerald, Indicolite Tourmaline, Iolite, Jadeite, Mahenge Spinel,
       Mandarin Spessartite Garnet, Paraiba Tourmaline, Peridot, Pink Sapphire,
       Pink Spinel, Pink Tourmaline, Purple Garnet, Rubellite Tourmaline, Ruby,
       Swiss Blue Topaz, Tanzanite, Tsavorite Garnet.
       </p>
   <br/>
     <AccordionHighEnd/>
   <br/>
   <br/>
   </div>

   <br/><br/>

   <div className="video">
       <Player
            fluid
            preload="none"
            LoadingSpinner
            poster={ImageHR}
            src={AR}
          />
   </div>

   <div align="center" className="token_interface">
   <br/>
   <p align="justify"><strong>Harlequin Ring:</strong> Platinum or 18k yellow gold,
      fused glass enamel and 65 diamonds with a total approximation weight of 2 carats.
      For the central oval colored gemstone, faceted oval brilliant cut or a non-faceted
      oval cabochon, you can select from the following gemstones:
      Alexandrite, Amethyst, Aquamarine, Benitoite, Black Opal, Blue Sapphire,
      Chrome Tourmaline, Emerald, Indicolite Tourmaline, Iolite, Jadeite,
      Mahenge Spinel, Paraiba Tourmaline, Peridot, Pink Sapphire, Pink Spinel,
      Pink Tourmaline (showing above), Purple Garnet, Rubellite Tourmaline,
      Ruby, Swiss Blue Topaz, Tanzanite, Tsavorite Garnet.
      </p>
   <br/>
     <AccordionHighEnd/>
   <br/>
   <br/>
   </div>

   <br/><br/>

   <div className="video">
       <Player
            fluid
            preload="none"
            LoadingSpinner
            poster={ImageDTE}
            src={DTE}
          />
   </div>

   <div align="center" className="token_interface">
   <br/>
   <p align="justify"><strong>Dionysus Tears Earrings:</strong> Platinum or 18k
   yellow gold, fused glass enamel and 1,155 diamonds with a total approximation
   weight of 8.5 carats. Colored Round Brilliants and Briolettes can be selected
   from the following gemstones: Alexandrite, Amethyst, Citrine, Chrome Tourmaline,
   Emerald, Imperial Topaz, Iolite, Jadeite, Mahenge Spinel, Mandarin Spessartite
   Garnet, Orange Sphalerite, Mexican Opal, Paraiba Tourmaline, Peridot, Pink
   Sapphire, Pink Spinel, Pink Tourmaline, Purple Garnet, Rubellite Tourmaline,
   Ruby, Tsavorite Garnet.

     </p>
   <br/>
     <AccordionHighEnd/>
   <br/>
   <br/>
   </div>

  <div className="buttons-bar">
      <Menubottom/>
  </div>
  <div className="bottom-strip">
  <br/><br/>
  <Link to="/PrivacyPolicy">Privacy Policy</Link>
  <br/>
  </div>

  </main>
  );
  }

export default HighEnd;
