import React from 'react';
//import React, { useEffect } from "react";
//import ReactDOM from "react-dom"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


class SandboxPayPal extends React.Component {
  createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
        description:"Jewelry Course SKU:JCDRM2022V1",
          amount: {
            value: "1.10",
          },
        },
      ],
    });
  }


  onApprove(data, actions) {
    return actions.order.capture().then(function ()
    {window.location.href = "./CourseThankYou"}
  );
}

  render() {
    return (


      <PayPalScriptProvider options={{
        "client-id": "AQFRgvPpHzkp51IJY7NJgMXFzt0rm5muOfIJbvgjbp24u9hkEYzre-30rhBJjfLTwEIMVTbCh5KHKifP" }}>
                  <PayPalButtons
                  style={{ layout: "vertical", color: "gold", shape: "rect", label: "pay", height: 40 }}
                  createOrder={(data, actions) => this.createOrder(data, actions)}
                  onApprove={(data, actions) => this.onApprove(data, actions)}
                  />
              </PayPalScriptProvider>


    );
  }
}

export default SandboxPayPal;
