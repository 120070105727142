//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSS30ROSE45 from './images/ESSS30ROSE45_1920x1080.jpg';
import ImageESSK34EMER01 from './images/ESSK34EMER01_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import Pagination3 from './navigation/pagination3';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';

import VideoESSS30ROSE45 from './videos/ESSS30ROSE45.mp4';
import VideoESSK34EMER01 from './videos/ESSK34EMER01.mp4';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ESSS30ROSE45PayPal from './paypal/ESSS30ROSE45PayPal.js'
import ESSK34EMER01PayPal from './paypal/ESSK34EMER01PayPal.js'
//import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


function App3() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSS30ROSE45}
             src={VideoESSS30ROSE45}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Rose and Aurore Boreale crystals from Swarovski®.  Hammered texture and
    polished like a mirror. Extremely comfortable to wear with a sterling silver
    post and clutch disc system. Made in USA with imported Swarovski® crystals
    from Austria. Play the video in full screen to appreciate the beauty and craftsmanship.
    </p>
    <p align="center">
     <strong>Price $140.00 (30% off from $200.00)</strong>
    </p>
    <p>
    <strong>Free USPS Priority Mail Shipping</strong>
    </p>
    <ESSS30ROSE45PayPal/>
    </div>

          <br/><br/>

          <div className="video">

              <Player
                   fluid
                   preload='none'
                   LoadingSpinner
                   poster={ImageESSK34EMER01}
                   src={VideoESSK34EMER01}
                 />

          </div>

          <div align="center" className="token_interface">
          <br/>
          <p align="justify">Handmade sterling silver earrings, embellished with
          Emerald crystals from Swarovski®.  Hammered texture and polished like a mirror.
          Extremely comfortable to wear with a sterling silver post and clutch disc system.
          Made in USA with imported Swarovski® crystals from Austria. Play the video
          in full screen to appreciate the beauty and craftsmanship.
          </p>
          <p>
          <strong>Price $168.75 (25% off from $225.00)</strong>
          </p>
          <p>
          <strong>Free USPS Priority Mail Shipping</strong>
          </p>
          <ESSK34EMER01PayPal/>
             </div>
          <div className="pagination">
          <Pagination3/>
          </div>
          <div className="buttons-bar">
          <Menubottom/>
          </div>
          <div className="bottom-strip">
          <br/><br/>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <br/>
          </div>

                </main>
              );
            }

            export default App3;
