//import React, { Component } from 'react';
import React from 'react';
//import React, {useEffect} from 'react';
//import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import Menu from './navigation/menu';
import Menubottomcourses from './navigation/menubottomcourses';
//import Pagination from './navigation/pagination';
//import AccordionInfo from './accordionInfo';
//import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';


function CourseThankYou() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div align="center" className="token_interface">
    <br/>
    <h1>Thank you for enrolling in the course</h1>
    <br/>
    <p align="left">You will receive an email within the next 24 hours with your
    login information to access the lessons, calendar, related files and instructions.</p>
    <br/>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>

            <div className="buttons-bar">
                <Menubottomcourses/>
                </div>

                </main>
              );
            }

            export default CourseThankYou;
