import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
//import OwnersMJMB from './ownersMJMB';

export default class AccordionHighEnd extends Component {

  state = {activeIndex: 4}

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (

      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
          <Icon name='dropdown' />
          Design available for licensing, click here for more information.
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
        <div>
        <p align="justify">
        This design is available for licensing.
        We can guarantee the exclusivity and scarce of this design with limited editions.
        The granted copyright with registration (VAU001360429) allows us to control the
        number of units manufactured and guarantee the design exclusivity.
        We provide all the necessaries 3D CAD files for 3D printing manufacturing: wax 3D
        printing for metal casting or Direct Metal Laser Sintering (DMLS) 3D printing.
        </p>
        </div>
        </Accordion.Content>
      </Accordion>

    )
  }
}
