import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react';


class Menubottomthankyou extends Component {

  render(){
      return(

      <Button.Group widths='2' color='pink'>

      <Modal trigger={<Button>Shipping & Return Policy</Button>}closeIcon>
      <Modal.Content scrolling>

      <Modal.Description>

          <p align="center"><strong>Shipping</strong></p>

          <p align="justify">Our products are shipped the next business day
          after receiving the order. We use USPS tracking system with Signature
          Confirmation™ Service. We ship via USPS Priority Mail 3 business days
          and offer free delivery for all US domestic shipping, including Hawaii,
          Alaska and US territories.
          </p>
          <br/>
          <p align="center"><strong>Return Policy</strong></p>
          <p align="justify">You may return new, unopened and unused items.
          We will refund the item as soon as it arrives to our address in
          unused original condition. The return window is 30 days after
          delivery. Items must be returned with insurance for the value of the
          merchandise, using a trackable and signature shipping service.
          </p>
          <br/>
          <p align="justify">the following scenarios do not qualify for returns.
          </p>

          <p align="justify">•	Items that are damaged, missing parts, not in
          the original condition, or have obvious signs of use for reasons not
          due to the seller.
          </p>

          <p align="justify">•	Items that have been resized, damaged, or
          otherwise altered after delivery won't be accepted for return.
          </p>

          <p align="justify">•	All product packaging (boxes, manuals, warranty
          cards, etc.) and certificates of authenticity, grading, and appraisal
          must be returned with the item. Any items returned without original
          documentation will be rejected.
          </p>

        </Modal.Description>
      </Modal.Content>
     </Modal>
        <Button as={Link} to={"/"}>Home</Button>
          </Button.Group>


      )
  }
}



export default Menubottomthankyou;
