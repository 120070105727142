//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSC16XIAB01 from './images/ESSC16XIAB01_1920x1080.jpg';
import ImageESSK34TANZ01 from './images/ESSK34TANZ01_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import Pagination4 from './navigation/pagination4';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';

import VideoESSC16XIAB01 from './videos/ESSC16XIAB01.mp4';
import VideoESSK34TANZ01 from './videos/ESSK34TANZ01.mp4';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ESSC16XIAB01PayPal from './paypal/ESSC16XIAB01PayPal.js'
//import ESSK34TANZ01PayPal from './paypal/ESSK34TANZ01PayPal.js'
//import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


function App4() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSC16XIAB01}
             src={VideoESSC16XIAB01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Xirius and Aurore Boreale crystals from Swarovski®.  Hammered texture and
    polished like a mirror. Extremely comfortable to wear with a sterling silver
    post and clutch disc system. Made in USA with imported Swarovski® crystals
    from Austria. Play the video in full screen to appreciate the beauty and craftsmanship.
    </p>
    <p align="center">
    <strong>Price $125.00</strong>
    </p>
    <p>
    <strong>Free USPS Priority Mail Shipping</strong>
    </p>
    <ESSC16XIAB01PayPal/>
    </div>


          <br/><br/>

          <div className="video">

              <Player
                   fluid
                   preload='none'
                   LoadingSpinner
                   poster={ImageESSK34TANZ01}
                   src={VideoESSK34TANZ01}
                 />

          </div>

          <div align="center" className="token_interface">
          <br/>
          <p align="justify">Handmade sterling silver earrings, embellished with
          Tanzanite crystals from Swarovski®.  Hammered texture and polished like a mirror.
          Extremely comfortable to wear with a sterling silver post and clutch disc system.
          Made in USA with imported Swarovski® crystals from Austria. Play the video
          in full screen to appreciate the beauty and craftsmanship.
          </p>
          <h2>Sold</h2>
          <br/>
          </div>

          <div className="pagination">
          <Pagination4/>
          </div>
          <div className="buttons-bar">
          <Menubottom/>
          </div>
          <div className="bottom-strip">
          <br/><br/>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <br/>
          </div>

                </main>
              );
            }

            export default App4;
