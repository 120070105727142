//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSH16DRBE01 from './images/ESSH16DRBE01_1920x1080.jpg';
import ImageESSS30BZIR45 from './images/ESSS30BZIR45_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import Pagination2 from './navigation/pagination2';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
import VideoESSH16DRBE01 from './videos/ESSH16DRBE01.mp4';
import VideoESSS30BZIR45 from './videos/ESSS30BZIR45.mp4';
//import ESSH16DRBE01PayPal from './paypal/ESSH16DRBE01PayPal.js'
import ESSS30BZIR45PayPal from './paypal/ESSS30BZIR45PayPal.js'



function App2() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSH16DRBE01}
             src={VideoESSH16DRBE01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with Teardrop
    Aurore Boreale crystals from Swarovski®.  Hammered texture and polished like a mirror.
    Extremely comfortable to wear with a sterling silver post and clutch disc system.
    Made in USA with imported Swarovski® crystals from Austria. Play the video in
    full screen to appreciate the beauty and craftsmanship.
    </p>

    <h2>Sold</h2>

    <br/>
    </div>
<br/><br/>

       <div className="video">

           <Player
                fluid
                preload='none'
                LoadingSpinner
                poster={ImageESSS30BZIR45}
                src={VideoESSS30BZIR45}
              />

       </div>

       <div align="center" className="token_interface">
       <br/>
       <p align="justify">Handmade sterling silver earrings, embellished with
       Blue Zircon and Aurore Boreale crystals from Swarovski®.  Hammered texture
       and polished like a mirror. Extremely comfortable to wear with a sterling
       silver post and clutch disc system. Made in USA with imported Swarovski®
       crystals from Austria. Play the video in full screen to appreciate the
       beauty and craftsmanship.
       </p>
       <p>
       <strong>Price $140.00 (30% off from $200.00)</strong>
       </p>
       <p>
       <strong>Free USPS Priority Mail Shipping</strong>
       </p>
       <ESSS30BZIR45PayPal/>
       </div>

       <div className="pagination">
       <Pagination2/>
       </div>
       <div className="buttons-bar">
       <Menubottom/>
       </div>
       <div className="bottom-strip">
       <br/><br/>
       <Link to="/PrivacyPolicy">Privacy Policy</Link>
       <br/>
       </div>

                </main>
              );
            }

            export default App2;
