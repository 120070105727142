//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSS28LSIA01 from './images/ESSS28LSIA01_1920x1080.jpg';
import ImageESSC16LSAP02 from './images/ESSC16LSAP02_1920x1080.jpg';
import ImageESSK38LSIA01 from './images/ESSK38LSIA01_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import Pagination6 from './navigation/pagination6';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';

import VideoESSS28LSIA01 from './videos/ESSS28LSIA01.mp4';
import VideoESSC16LSAP02 from './videos/ESSC16LSAP02.mp4';
import VideoESSK38LSIA01 from './videos/ESSK38LSIA01.mp4';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ESSS28LSIA01PayPal from './paypal/ESSS28LSIA01PayPal.js'
//import ESSC16LSAP02PayPal from './paypal/ESSC16LSAP02PayPal.js'
//import ESSK38LSIA01PayPal from './paypal/ESSK38LSIA01PayPal.js'
//import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';


function App6() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSS28LSIA01}
             src={VideoESSS28LSIA01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Light Siam red and Aurore Boreale crystals from Swarovski®.  Hammered texture and
    polished like a mirror. Extremely comfortable to wear with a sterling silver
    post and clutch disc system. Made in USA with imported Swarovski® crystals
    from Austria. Play the video in full screen to appreciate the beauty and craftsmanship.
    </p>
    <p align="center">
    <strong>Price $105.00 (30% off from $150.00)</strong>
    </p>
    <p>
    <strong>Free USPS Priority Mail Shipping</strong>
    </p>
    <ESSS28LSIA01PayPal/>
    </div>
<br/><br/>

       <div className="video">

           <Player
                fluid
                preload='none'
                LoadingSpinner
                poster={ImageESSC16LSAP02}
                src={VideoESSC16LSAP02}
              />

       </div>

       <div align="center" className="token_interface">
       <br/>
       <p align="justify">Handmade sterling silver earrings, embellished with
       Light Sapphire crystals from Swarovski®.  Hammered texture and
       polished like a mirror. Extremely comfortable to wear with a sterling
       silver post and clutch disc system. Made in USA with imported Swarovski®
       crystals from Austria. Play the video in full screen to appreciate the
       beauty and craftsmanship.
       </p>

       <h2>Sold</h2>

       <br/>
       </div>

          <br/><br/>

          <div className="video">

              <Player
                   fluid
                   preload='none'
                   LoadingSpinner
                   poster={ImageESSK38LSIA01}
                   src={VideoESSK38LSIA01}
                 />

          </div>

          <div align="center" className="token_interface">
          <br/>
          <p align="justify">Handmade sterling silver earrings, embellished with
          Light Siam red crystals from Swarovski®.  Hammered texture and polished
          like a mirror. Extremely comfortable to wear with a sterling silver
          post and clutch disc system. Made in USA with imported Swarovski®
          crystals from Austria. Play the video in full screen to appreciate the
          beauty and craftsmanship.
          </p>

          <h2>Sold</h2>

          <br/>
          </div>

          <div className="pagination">
          <Pagination6/>
          </div>
          <div className="buttons-bar">
          <Menubottom/>
          </div>
          <div className="bottom-strip">
          <br/><br/>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
          <br/>
          </div>

                </main>
              );
            }

            export default App6;
