import React, { Component } from 'react';
import { Accordion, Icon, List, Button } from 'semantic-ui-react';
import CoursePayPal from '../paypal/CoursePayPal.js';
//import OwnersMJMB from './ownersMJMB';

export default class AccordionCoursesFluid extends Component {

  state = {activeIndex: -1}

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (

      <Accordion>
        <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
        <p align="left"><strong>
        <Icon name='dropdown' />
        Artisan Jewelry Course $50:
        </strong></p>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
        <div>
        <p align="justify">
        In this course, you will learn how to design, render and
        manufacture these stunning jewelry designs.  You will acquire sophisticated
        professional techniques to design high-end fine jewelry out of the ordinary.
        No prior experience in jewelry design is needed, the teacher will help you at
        every step of the creative process and constantly monitor the student's progress.
        Unlike other e-learning platforms, Yari Nanichi courses extends well beyond
        videos; the course encourage you to finish the designs as 3D printing wax
        patterns ready for the lost wax casting technique or direct metal 3D printing.
        You will enjoy an extremely gratified creative process experience, from
        abstract ideas to a beautiful jewelry piece ready to wear.
        <br/>
        <br/>
        The course lasts three months. I am very
        flexible with my students, if for any reason you miss one lesson or if you do
        not complete all the designs, within the three-month course period, you may
        repeat the course for an additional three months at no additional cost. The
        lessons will be given in English and Spanish as live streams with Google Meet
        on Mondays and Wednesdays, from 1:00pm to 4:00pm (US Eastern Time,
        UTC/GMT -04.00 New York), then the same lesson will be repeated from 5:00pm
        to 8:00pm. On Saturdays from 10:00am to 5:00pm all lessons will be repeated
        for those who could not take them during the week. Also, recorded videos of
        the lessons are available for students to continue their studies.
        <br/>
        <br/>
        For international students, outside US, please contact us
        for a special schedule based on your time zone.
        </p>

        <p align="left"><strong>what will you learn</strong></p>
        <List align="justify" bulleted>
        <List.Item>CAD for Jewelry Design using Rhinoceros 3D software (the jewelry
        design industry standard).</List.Item>
        <List.Item>You'll learn 3D modeling for designing and 3D printing for casting your jewelry.</List.Item>
        <List.Item>Plan, create, develop, and/or maintain presentation quality artwork
        including photo-realistic concept renderings. Training in 3D jewelry video renderings to
        produce jewelry videos for advertise, social media and e-commerce.</List.Item>
        <List.Item>Creative design training with high degree of aesthetic sensibility
        and adapting philosophy of art and theory of knowledge for art conceptualization.</List.Item>
        <List.Item>Design experience with gemstones/diamonds, diverse materials and metal alloys.</List.Item>
        <List.Item>How to develop a successful design/collection.</List.Item>
        <List.Item>All CAD designs include the manufacturing strategies, technologies
        and processes required to achieve the design (manufacturing recipe). </List.Item>
        <List.Item>Ensure all models created are in keeping with modeling best practices
        and manufacturing standards (e.g., closed solid objects, minimal object density,
        model/structure organization, etc.).</List.Item>
        <List.Item>Complete understanding to interface with Design Creative, Manufacturing
        teams (Model Makers, Quality & Standards, Engineering) on product functionality,
        and technical design elements including form, style, details, weight and materials.
        The CAD Designer and manufacturing experience allow you to translate Design Concepts
        into reality. You can visualize in advance the problems and challenges at every
        manufacturing stage and diagnose the measures required to ease the production
        workflow in each manufacturing step by modifying the design with alternative
        variations and/or manufacturing techniques.</List.Item>
        </List>
        <br/>
        <p align="left"><strong>Requirements</strong></p>

        <List align="justify" bulleted>
        <List.Item>A Windows PC or MAC (The teacher uses the PC version)</List.Item>
        <List.Item>You will need Rhinoceros 3D, version 6 or 7 software.  A 90-day evaluation version
        that you can use for the course is available at:
        </List.Item>
        <br/>
        <Button align="left" href="https://www.rhino3d.com/download/" target="_blank">Rhinoceros 3D Download</Button>
        </List>
        <br/>
        <br/>
        <p align="justify">If you have always wanted to learn how to design and make
        jewelry, this is a great opportunity to start.
        </p>
        <br/>
        <br/>
        <p><strong>Artisan Jewelry Course Price: $50.00</strong></p>

        <p>15-Day Money-Back Guarantee</p>
        <CoursePayPal/>
        </div>
        </Accordion.Content>

        <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
        <p align="left"><strong>
        <Icon name='dropdown' />
        Advanced Professional Course $250:
        </strong></p>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
        <div>
        <p align="justify">
        In this course, you will learn how to design, render and
        manufacture these stunning jewelry designs from the video above.  You will acquire sophisticated
        professional techniques to design high-end fine jewelry out of the ordinary.
        No prior experience in jewelry design is needed, the teacher will help you at
        every step of the creative process and constantly monitor the student's progress.
        Unlike other e-learning platforms, Yari Nanichi courses extends well beyond
        videos; the course encourage you to finish the designs as 3D printing wax
        patterns ready for the lost wax casting technique or direct metal 3D printing.
        You will enjoy an extremely gratified creative process experience, from
        abstract ideas to a beautiful jewelry piece ready to wear.
        <br/>
        <br/>
        The course lasts three months. I am very
        flexible with my students, if for any reason you miss one lesson or if you do
        not complete all the designs, within the three-month course period, you may
        repeat the course for an additional three months at no additional cost. The
        lessons will be given in English and Spanish as live streams with Google Meet
        on Mondays and Wednesdays, from 1:00pm to 4:00pm (US Eastern Time,
        UTC/GMT -04.00 New York), then the same lesson will be repeated from 5:00pm
        to 8:00pm. On Saturdays from 10:00am to 5:00pm all lessons will be repeated
        for those who could not take them during the week. Also, recorded videos of
        the lessons are available for students to continue their studies.
        <br/>
        <br/>
        For international students, outside US, please contact us
        for a special schedule based on your time zone.
        </p>

        <p align="left"><strong>what will you learn</strong></p>
        <List align="justify" bulleted>
        <List.Item>CAD for Jewelry Design using Rhinoceros 3D software (the jewelry
        design industry standard).</List.Item>
        <List.Item>You'll learn 3D modeling for designing and 3D printing for casting your jewelry.</List.Item>
        <List.Item>Plan, create, develop, and/or maintain presentation quality artwork
        including photo-realistic concept renderings. Training in 3D jewelry video renderings to
        produce jewelry videos for advertise, social media and e-commerce.</List.Item>
        <List.Item>Creative design training with high degree of aesthetic sensibility
        and adapting philosophy of art and theory of knowledge for art conceptualization.</List.Item>
        <List.Item>Design experience with gemstones/diamonds, diverse materials and metal alloys.</List.Item>
        <List.Item>How to develop a successful design/collection.</List.Item>
        <List.Item>All CAD designs include the manufacturing strategies, technologies
        and processes required to achieve the design (manufacturing recipe). </List.Item>
        <List.Item>Ensure all models created are in keeping with modeling best practices
        and manufacturing standards (e.g., closed solid objects, minimal object density,
        model/structure organization, etc.).</List.Item>
        <List.Item>Complete understanding to interface with Design Creative, Manufacturing
        teams (Model Makers, Quality & Standards, Engineering) on product functionality,
        and technical design elements including form, style, details, weight and materials.
        The CAD Designer and manufacturing experience allow you to translate Design Concepts
        into reality. You can visualize in advance the problems and challenges at every
        manufacturing stage and diagnose the measures required to ease the production
        workflow in each manufacturing step by modifying the design with alternative
        variations and/or manufacturing techniques.</List.Item>
        </List>
        <br/>
        <p align="left"><strong>Requirements</strong></p>

        <List align="justify" bulleted>
        <List.Item>A Windows PC or MAC (The teacher uses the PC version)</List.Item>
        <List.Item>You will need Rhinoceros 3D, version 6 or 7 software.  A 90-day evaluation version
        that you can use for the course is available at:
        </List.Item>
        <br/>
        <Button align="left" href="https://www.rhino3d.com/download/" target="_blank">Rhinoceros 3D Download</Button>
        </List>
        <br/>
        <br/>
        <p align="justify">If you have always wanted to learn how to design and make
        jewelry, this is a great opportunity to start.
        </p>
        <br/>
        <br/>
        <p><strong>Advanced Course Price: $250.00</strong></p>

        <p>15-Day Money-Back Guarantee</p>
        <CoursePayPal/>
        </div>
        </Accordion.Content>
      </Accordion>

    )
  }
}
