//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import ImageESSP32ROSE01 from './images/ESSP32ROSE01_1920x1080.jpg';
import ImageESSC32LSAP01 from './images/ESSC32LSAP01_1920x1080.jpg';
import Menu from './navigation/menu';
import Menubottom from './navigation/menubottom';
import Pagination5 from './navigation/pagination5';
//import AccordionInfo from './accordionInfo';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
import VideoESSP32ROSE01 from './videos/ESSP32ROSE01.mp4';
import VideoESSC32LSAP01 from './videos/ESSC32LSAP01.mp4';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
//import ESSP32ROSE01PayPal from './paypal/ESSP32ROSE01PayPal.js'
//import ESSC32LSAP01PayPal from './paypal/ESSC32LSAP01PayPal.js';


function App5() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSP32ROSE01}
             src={VideoESSP32ROSE01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Rose and Aurore Boreale crystals from Swarovski®.  Hammered texture and polished like a mirror.
    Extremely comfortable to wear with a sterling silver post and clutch disc system.
    Made in USA with imported Swarovski® crystals from Austria. Play the video
    in full screen to appreciate the beauty and craftsmanship.
    </p>
    <h2>Sold</h2>
    <br/>
    </div>
    <br/><br/>

    <div className="video">

        <Player
             fluid
             preload='none'
             LoadingSpinner
             poster={ImageESSC32LSAP01}
             src={VideoESSC32LSAP01}
           />

    </div>

    <div align="center" className="token_interface">
    <br/>
    <p align="justify">Handmade sterling silver earrings, embellished with
    Light Sapphire crystals from Swarovski®.  Hammered texture and polished like a mirror.
    Extremely comfortable to wear with a sterling silver post and clutch disc system.
    Made in USA with imported Swarovski® crystals from Austria. Play the video
    in full screen to appreciate the beauty and craftsmanship.
    </p>

    <h2>Sold</h2>

    <br/>
    </div>
    <div className="pagination">
    <Pagination5/>
    </div>
    <div className="buttons-bar">
    <Menubottom/>
    </div>
    <div className="bottom-strip">
    <br/><br/>
    <Link to="/PrivacyPolicy">Privacy Policy</Link>
    <br/>
    </div>

                </main>
              );
            }

            export default App5;
