//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from './images/logo_text.png';
import MenuCourses from './navigation/menucourses';
//import CoursesTabs from './navigation/coursestabs';
import Menubottomcourses from './navigation/menubottomcourses';
import Accordioncourses from './components/accordioncourses';
import ImageMJMB from './images/MJMB.png';
import { Player } from 'video-react';
import '../node_modules/video-react/dist/video-react.css';
import Coursevideo from './videos/course.mp4';
//import CoursePayPal from './paypal/CoursePayPal.js';
//import { List, Button } from 'semantic-ui-react'
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";



function Coursesv2() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    <p><strong>Art To Wear Jewelry Courses</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <MenuCourses/>
    </div>

    <div className="video">
        <Player
             fluid
             preload="none"
             LoadingSpinner
             muted={true}
             poster={ImageMJMB}
             src={Coursevideo}
           />
    </div>

    <div align="center" className="token_interface">
    <br/>
    <h3>Jewelry Design, 3D Rendering and Manufacturing Courses</h3>
    <br/>
    <p align="left"><strong>Select from the courses bellow for a full description.</strong></p>
    <Accordioncourses/>
    <br/>
    <br/>
    <br/><br/><br/><br/>
    </div>
    <div className="buttons-bar">
    <Menubottomcourses/>
    </div>
    <div className="bottom-strip">
    <br/><br/><br/>
    <Link to="/PrivacyPolicy">Privacy Policy</Link>
    <br/>
    </div>


    </main>
  );
  }

export default Coursesv2;
