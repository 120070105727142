//import React, { Component } from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import Logo_Text from '../../images/logo_text.png';
import Menu from '../../navigation/menu';
import Menubottom from '../../navigation/menubottom';
import Image from '../../images/ESSH16DRBE01_1920x1080.jpg';
import { Player } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css';
import Video from '../../videos/ESSH16DRBE01.mp4';
import ESSH16DRBE01PayPal from '../../paypal/ESSH16DRBE01PayPal.js';
//import AccordionInfo from './accordionInfo';

function ESSH16DRBE01() {


  return (

    <main className="container">
    <div className="logo">
    <br/><br/>
    <Link to="/">
    <img src={Logo_Text} alt="Yari Nanichi-logo" />
    </Link>
    <p><strong>Art To Wear Jewelry</strong></p>
    <br/>
    </div>

    <div className="buttons-bar">
    <Menu/>
    </div>

             <div className="video">

                 <Player
                      fluid
                      preload='none'
                      LoadingSpinner
                      poster={Image}
                      src={Video}
                    />

             </div>

             <div align="center" className="token_interface">
             <br/>
             <p align="justify">Handmade sterling silver earrings, embellished with
             Teardrop Aurore Boreale crystals from Swarovski®.  Hammered texture and polished like a mirror.
             Extremely comfortable to wear with a sterling silver post and clutch disc system.
             Made in USA with imported Swarovski® crystals from Austria. Play the video
             in full screen to appreciate the beauty and craftsmanship.
             </p>
             <p>
             <strong>Price $80.00</strong>
             </p>
             <p>
             <strong>Free USPS Priority Mail Shipping</strong>
             </p>
             <ESSH16DRBE01PayPal/>
             </div>


            <div className="buttons-bar">
                <Menubottom/>
                </div>

                </main>
              );
            }

            export default ESSH16DRBE01;
