import React from 'react';
//import ReactDOM from "react-dom"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


class ESSC36LTQU01PayPal extends React.Component {
  createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
        description:"Earrings SKU:ESSC36LTQU01",
          amount: {
            value: "168.75",
          },
        },
      ],
    });
  }

  onApprove(data, actions) {
    return actions.order.capture().then(function ()
    {window.location.href = "./ThankYou"}
  );
}


  render() {
    return (

<div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <PayPalScriptProvider options={{
        "client-id": "ATXENFz86AulTLTjJ721RrUKBDZ0YD6kH729ux9XXACacrBB1IsFYT_IqXKFX_W1VBrRIfltwrLb4YrH" }}>
                  <PayPalButtons
                  style={{ layout: "vertical", color: "gold", shape: "rect", label: "pay", height: 40 }}
                  createOrder={(data, actions) => this.createOrder(data, actions)}
                  onApprove={(data, actions) => this.onApprove(data, actions)}
                  />
              </PayPalScriptProvider>
</div>

    );
  }
}

export default ESSC36LTQU01PayPal;
